import * as React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import MDInput from "../../components/MDInput";
import Grid from "@mui/material/Grid";
import "assets/css/style.css"
import {useEffect, useState} from "react";
import FormField from "./Component/FormField";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "../../components/selectData";
import Switch from "@mui/material/Switch";
import MDAlert from "../../components/MDAlert";
import reactStringReplace from "react-string-replace";
import {api} from "../../assets/boot/axios";
// import sales from "../dashboards/sales";


const DialogEdit = (props) => {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        salutation: "Familie",
        street: "",
        streetNumber: "",
        plz: 0,
        city: "",
        telephone: "",
        email: "",
        type: "Sunny",
        pets: false,
        dog: false,
        adults: 0,
        children: 0,
        contractDate: setToday(),
        rentStart: setToday(),
        rentEnd: setToday(),
        pricePerNight: 0,
        priceRent: 0,
        cleaning: 99,
        amountOfDog: 0,
        pricePerDog: 0,
        amountOfLaundry: 0,
        pricePerLaundry: 20,
        kurtaxe: 0,
        reduction: 0,
        finalPrice: 0,
        restPayment:0,
        deposit: 150
    })

    const [nights, setNight] = useState({
        nights: 0,
    })
    const [priceRent, setPriceRent] = useState({
        priceRent: 0,
    })
    const [finalPrice, setFinalPrice] = useState({
        finalPrice: 0,
    })
    const [updateInput, setUpdateInput] = useState({
        name: "",
        type: "",
        value: null,
        state: false,
    })
    const [sendAlertState, setSendAlertState] = useState(false)

    const [validate, setValidate] = React.useState({
        firstName: false,
        lastName: false,
        salutation: true,
        street: false,
        streetNumber: false,
        plz: false,
        city: false,
        telephone: true,
        email: false,
        type: true,
        pets: true,
        dog: true,
        adults: false,
        children: true,
        contractDate: true,
        rentStart: false,
        rentEnd: false,
        pricePerNight: false,
        cleaning: true,
        amountOfDog: false,
        pricePerDog: false,
        amountOfLaundry: true,
        pricePerLaundry: true,
        kurtaxe: false,
        reduction: true,
        nights: false,
        deposit: true
    })

    const [falseValue, setFalseValue] = useState({})

    let amountDogs
    let priceDogs
    let sendAlert
    let webSpacing

    if (state.pets) {
        amountDogs = <Grid display="flex" alignItems="center" item xs={12} sm={6}>
            <FormField type="number" label="Anzahl der Hunde" name="amountOfDog"
                     onChange={handleChange} value={state.amountOfDog} variant="standard"/>
        </Grid>;
        priceDogs = <Grid item xs={12} sm={6}>
            <FormField type="number" label="Pro Hund €" name="pricePerDog" onChange={handleChange}
                     value={state.pricePerDog} variant="standard"/>
        </Grid>
    }else{
        amountDogs = <Grid display="flex" alignItems="center" item xs={12} sm={6}></Grid>;
    }
    // Wenn Webansicht ist
    if(window.innerHeight > 700){
        webSpacing = <Grid display="flex" alignItems="center" item xs={12} sm={12}></Grid>;
    }
    if (sendAlertState) {
        let falseValueKeys = falseValue
        let falseValueRightLangueage = []
        falseValueKeys.forEach(key => {
                switch (key) {
                    case 'nights':
                        if (!falseValueRightLangueage.includes('Nächte')) {
                            falseValueRightLangueage.push('Nächte')
                        }
                        break;
                   case 'salutation':
                        if (!falseValueRightLangueage.includes('Anrede')) {
                            falseValueRightLangueage.push('Anrede')
                        }
                        break;
                   case 'type':
                        if (!falseValueRightLangueage.includes('Ferienhaus')) {
                            falseValueRightLangueage.push('Ferienhaus')
                        }
                        break;
                    case 'firstName':
                        if (!falseValueRightLangueage.includes('Vorname')) {
                            falseValueRightLangueage.push('Vorname')
                        }
                        break;
                    case 'lastName':
                        if (!falseValueRightLangueage.includes('Nachname')) {
                            falseValueRightLangueage.push('Nachname')
                        }
                        break;
                    case 'street':
                        if (!falseValueRightLangueage.includes('Straße')) {
                            falseValueRightLangueage.push('Straße')
                        }
                        break;
                    case 'streetNumber':
                        if (!falseValueRightLangueage.includes('Hausnummer')) {
                            falseValueRightLangueage.push('Hausnummer')
                        }
                        break;
                    case 'plz':
                        if (!falseValueRightLangueage.includes('PLZ')) {
                            falseValueRightLangueage.push('PLZ')
                        }
                        break;
                    case 'city':
                        if (!falseValueRightLangueage.includes('Stadt')) {
                            falseValueRightLangueage.push('Stadt')
                        }
                        break;
                    case 'email':
                        if (!falseValueRightLangueage.includes('Email')) {
                            falseValueRightLangueage.push('Email')
                        }
                        break;
                    case 'adults':
                        if (!falseValueRightLangueage.includes('Erwachsene')) {
                            falseValueRightLangueage.push('Erwachsene')
                        }
                        break;
                    case 'children':
                        if (!falseValueRightLangueage.includes('Kinder')) {
                            falseValueRightLangueage.push('Kinder')
                        }
                        break;
                    case 'rentStart':
                        if (!falseValueRightLangueage.includes('Mietbeginn')) {
                            falseValueRightLangueage.push('Mietbeginn')
                        }
                        break;
                    case 'rentEnd':
                        if (!falseValueRightLangueage.includes('Mietende')) {
                            falseValueRightLangueage.push('Mietende')
                        }
                        break;
                    case 'pricePerNight':
                        if (!falseValueRightLangueage.includes('Preis pro Nacht')) {
                            falseValueRightLangueage.push('Preis pro Nacht')
                        }
                        break;
                    case 'kurtaxe':
                        if (!falseValueRightLangueage.includes('Kurtaxe')) {
                            falseValueRightLangueage.push('Kurtaxe')
                        }
                        break;
                    case 'reduction':
                        if (!falseValueRightLangueage.includes('Rabatt')) {
                            falseValueRightLangueage.push('Rabatt')
                        }
                        break;
                    case 'amountOfLaundry':
                        if (!falseValueRightLangueage.includes('Anzahl Wäschepaket(e)')) {
                            falseValueRightLangueage.push('Anzahl Wäschepaket(e)')
                        }
                        break;
                    case 'deposit':
                        if (!falseValueRightLangueage.includes('Anzahlung')) {
                            falseValueRightLangueage.push('Anzahlung')
                        }
                        break;
                }
            }
        );

        let string = JSON.stringify(falseValueRightLangueage)
        let newString = reactStringReplace(string, /\"\,(\w*)\"|\"(\w*)\]|\[(\w*)\"/, (match, i) => (
            <span key={i}>, </span>))
        sendAlert =
            // <MDAlert color="error" children>
            <Card style={{backgroundColor: "#d22525"}}>
                <MDBox component="form" py={3} px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <p style={{fontSize: "1rem", color: "white"}}>
                                Folgende Felder wurden nicht richtig ausgefüllt:
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <p style={{fontSize: "0.9rem", color: "white"}}>
                                {newString}
                            </p>
                        </Grid>
                    </Grid>
                </MDBox>

            </Card>
    }

    function handleChange(evt) {
        const fileValue = evt.target.value;
        const fileName = evt.target.name;
        const fileType = evt.target.type;

        setUpdateInput({
            ...updateInput,
            type: fileType,
            value: fileValue,
            name: fileName
        });
        setState({
            ...state,
            [evt.target.name]: fileValue
        });
    }

    function handleChangeSelect(newValue, name) {
        const fileValue = newValue;
        const fileName = name;
        const fileType = 'select';
        setUpdateInput({
            ...updateInput,
            type: fileType,
            value: fileValue,
            name: fileName
        });
        setState({
            ...state,
            [name]: newValue
        });
    }

    function handleChangeSwitch(newValue, name) {
        setState({
            ...state,
            [name]: !newValue
        });
    }

    useEffect(() => {
       checkPropsShow()
    },[props.show]);

    useEffect(() => {
        if(state.rentStart > state.rentEnd){
            setState({...state, rentEnd: state.rentStart})
        }
        SetNight()
    }, [state.rentStart, state.rentEnd]);

    useEffect(() => {
        SetPriceRent()
    }, [state.pricePerNight, nights.nights]);

   useEffect(() => {
        if (state.pets === false) {
            setState({
                ...state,
                amountOfDog: 0
            });
        }
    }, [state.pets]);
    useEffect(() => {
        validateField(updateInput.name, updateInput.value, updateInput.type)
    }, [state]);
    useEffect(() => {
        if (updateInput.name != "") {
            setValidate({
                ...validate,
                [updateInput.name]: updateInput.state
            });
        }
    }, [updateInput]);
    useEffect(() => {
        getFullPrice()
    }, [state, priceRent.priceRent]);
    useEffect(() => {
        SetFullPriceNew()
    }, [finalPrice.finalPrice]);

    function setToday() {
        let date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (mm < 10) {
            mm = '0' + mm;
        }
        if (dd < 10) {
            dd = '0' + dd;
        }
        let today = yyyy + '-' + mm + '-' + dd
        return today
    }
    function SetFullPriceNew(){
        let newValue = finalPrice.finalPrice
        setState({
            ...state,
            finalPrice: newValue,
            restPayment: newValue
        });
    }
    function checkPropsShow(){
        if (props.show){
            setSendAlertState(false)
        }
    }
    function SetNight() {
        let differenceInTime = new Date(state.rentEnd).getTime() - new Date(state.rentStart).getTime()
        let differenceInDays = differenceInTime / (1000 * 3600 * 24)
        setNight({nights: differenceInDays})
    }
    function SetPriceRent() {
        let newPriceRent = state.pricePerNight * parseInt(nights.nights)
        setPriceRent({priceRent: newPriceRent})
    }
    function validateField(fieldName, value, type) {
        let valideCheck;
        let valideCheckToday;
        let today = setToday()

        switch (type) {
            case 'email':
                valideCheck = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                setUpdateInput({
                    ...updateInput,
                    state: valideCheck === null ? false : true
                });
                break;
            case 'select':
                valideCheck = value
                setUpdateInput({
                    ...updateInput,
                    state: valideCheck === null ? false : true
                });
                break;
            case 'number':
                if (fieldName === "amountOfDog" || fieldName === "pricePerDog") {
                    setUpdateInput({
                        ...updateInput,
                        state: value.length >= 1 || value != 0
                    });
                }else if(fieldName === "adults" || fieldName === "deposit"){
                setUpdateInput({
                    ...updateInput,
                    state: value >= 1
                });
            }else {
                    setUpdateInput({
                        ...updateInput,
                        state: value >= 0
                    });
                }
                break;
            case 'date':
                // valideCheck = value.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/i)
                // valideCheckToday = value < today
                // if (valideCheckToday === false && valideCheck != null) {
                //     valideCheck = true
                // } else {
                //     valideCheck = false
                // }
                valideCheck = true
                setUpdateInput({
                    ...updateInput,
                    state: valideCheck === false ? false : true
                });
                break;
            case 'text':

                    setUpdateInput({
                        ...updateInput,
                        state: value.length >= 1
                    });

                break;
            default:
                break;
        }
    }
    function cancelObject(){
        setState({
            ...state,
            firstName: "",
            lastName: "",
            salutation: "Familie",
            street: "",
            streetNumber: "",
            plz: 0,
            city: "",
            telephone: "",
            email: "",
            type: "Sunny",
            pets: false,
            dog: false,
            adults: 0,
            children: 0,
            contractDate: setToday(),
            rentStart: setToday(),
            rentEnd: setToday(),
            pricePerNight: 0,
            priceRent: 0,
            cleaning: 99,
            amountOfDog: 0,
            pricePerDog: 0,
            amountOfLaundry: 0,
            pricePerLaundry: 20,
            kurtaxe: 0,
            reduction: 0,
            finalPrice: 0,
            restPayment:0
        })
        return props.close()
    }
    function valueCheck() {
        let falseValue = [];

        setSendAlertState(false)
        if (nights.nights <= 0) {
            falseValue.push("nights");
            setFalseValue([...falseValue, "nights"])
        }
        Object.keys(validate).forEach(key => {
            if (!validate[key]) {
                if (key === "amountOfDog" || key === "pricePerDog") {
                    if (state.pets) {
                        falseValue.push(key)
                        setFalseValue([...falseValue, key])
                    }
                } else if(key === "nights"){
                } else {
                    falseValue.push(key)
                    setFalseValue([...falseValue, key])
                }
            }
        })
        if (falseValue.length === 0) {
            sendAlert = null
            return props.save(state, props.row)
        } else {
            setSendAlertState(true)
        }
    }
    function getFullPrice() {
        let bookValues = {
            "amountOfDog": state.amountOfDog,
            "pricePerDog": state.pricePerDog,
            "amountOfLaundry": state.amountOfLaundry,
            "pricePerLaundry": state.pricePerLaundry,
            "kurtaxe": state.kurtaxe,
            "priceRent": priceRent.priceRent,
            "cleaning": state.cleaning,
            "reduction": state.reduction,
            "deposit": 0
        }
        //todo service an getFinalPriceWithBooking anpassen (deposit 0 mit schicken)
        api.post(`/booking/getFinalPriceWithBooking/`, bookValues)
            .then(response => {
                setFinalPrice({finalPrice: Math.round(response.data[0] * 100)/100});
            })
            .catch(error => {
                console.log("error", error);
            })
    }

    return (
        <div>
            <Dialog open={props.show} onClose={props.close}>
                <DialogTitle>Vertrag erstellen</DialogTitle>
                {sendAlert}
                <DialogContent component="form">

                    <Card id="info-person" sx={{overflow: "visible"}}>
                        <MDBox p={3}>
                            <DialogContentText>Persönliche Daten</DialogContentText>
                        </MDBox>
                        <MDBox component="form" pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        options={selectData.salutation}
                                        renderInput={(params) => (
                                            <FormField {...params} label="Anrede" name="salutation"
                                                       InputLabelProps={{shrink: true}}
                                            />
                                        )}
                                        value={state.salutation}
                                        onChange={(event: any, newValue: string | null) => {
                                            handleChangeSelect(newValue, "salutation")
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField type="text" label="Vorname" name="firstName" onChange={handleChange}
                                               value={state.firstName}/>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField type="text" label="Nachname" name="lastName" onChange={handleChange}
                                               value={state.lastName}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormField type="text" label="Straße" name="street" onChange={handleChange}
                                               value={state.street}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormField type="text" label="Hausnr" name="streetNumber" onChange={handleChange}
                                               value={state.streetNumber}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormField type="number" label="PLZ" name="plz" onChange={handleChange}
                                               value={state.plz}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormField type="text" label="Stadt" name="city" onChange={handleChange}
                                               value={state.city}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="tel" label="Handynummer" name="telephone" onChange={handleChange}
                                             value={state.telephone} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="email" label="Email" name="email" id="email"
                                               onChange={handleChange}
                                               value={state.email} variant="standard"/>
                                </Grid> </Grid>
                        </MDBox>
                    </Card>

                    <Card id="info-resort" sx={{overflow: "visible", mt: '1rem'}}>
                        <MDBox p={3}>
                            <DialogContentText>Ferienwohnung</DialogContentText>
                        </MDBox>
                        <MDBox component="form" pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        defaultValue="1"
                                        options={selectData.typHouse}
                                        renderInput={(params) => (
                                            <FormField {...params} label="Ferienhaus"
                                                       InputLabelProps={{shrink: true}}/>
                                        )}
                                        value={state.type}
                                        onChange={(event: any, newValue: string | null) => {
                                            handleChangeSelect(newValue, "type")
                                        }}
                                    />
                                </Grid>


                                <Grid display="flex" alignItems="center" item xs={12} sm={6}>
                                    <MDBox>
                                        <Switch checked={state.pets}
                                                onChange={() => handleChangeSwitch(state.pets, "pets")}/>
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <DialogContentText>
                                            Haustiere
                                        </DialogContentText>
                                    </MDBox>
                                </Grid>
                                {amountDogs}
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Erwachsene" name="adults" onChange={handleChange}
                                             value={state.adults} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Kinder" name="children" onChange={handleChange}
                                             value={state.children} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="date" label="Vertragsdatum" name="contractDate"
                                             onChange={handleChange}
                                             value={state.contractDate} variant="standard"/>
                                </Grid>
                                {webSpacing}
                                <Grid item xs={12} sm={6}>
                                    <FormField type="date" label="Mietbeginn" name="rentStart" onChange={handleChange}
                                             value={state.rentStart} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="date" label="Mietende" name="rentEnd" onChange={handleChange}
                                             value={state.rentEnd} variant="standard"/>
                                </Grid>
                                <Grid item xs={0} sm={12}>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField type="number" label="Nächte" name="nights"
                                             value={nights.nights} variant="standard" disabled/>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>

                    <Card id="info-price" sx={{overflow: "visible", mt: '1rem'}}>
                        <MDBox p={3}>
                            <DialogContentText>Preise</DialogContentText>
                        </MDBox>
                        <MDBox component="form" pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Pro Nacht €" name="pricePerNight"
                                             onChange={handleChange}
                                             value={state.pricePerNight} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Mietpreis €" name="priceRent"
                                             value={priceRent.priceRent} variant="standard" disabled/>
                                </Grid>
                                {priceDogs}
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Reinigung €" name="cleaning" onChange={handleChange}
                                             value={state.cleaning} variant="standard" />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Pro Wäschepack €" name="pricePerLaundry"
                                             onChange={handleChange}
                                             value={state.pricePerLaundry} variant="standard" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Anzahl Wäschepaket(e)" name="amountOfLaundry"
                                             onChange={handleChange}
                                             value={state.amountOfLaundry} variant="standard"/>
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Kurtaxe €" name="kurtaxe" onChange={handleChange}
                                             value={state.kurtaxe} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Rabat %" name="reduction" onChange={handleChange}
                                             value={state.reduction} variant="standard"/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormField type="number" label="Anzahlung €" name="deposit" onChange={handleChange}
                                             value={state.deposit} variant="standard"/>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>

                    <Card id="info-payment" sx={{overflow: "visible", mt: '1rem'}}>
                        <MDBox p={3}>
                            <DialogContentText>Gezahlt/Offen</DialogContentText>
                        </MDBox>
                        <MDBox component="form" pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <FormField type="number" label="Gesamter Rechnungsbetrag €" name="finalPrice"
                                             value={finalPrice.finalPrice} variant="standard" disabled
                                             onChange={() => SetFullPriceNew()}/>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelObject}>Abbrechen</Button>
                    <Button onClick={valueCheck}>Speichern</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogEdit;

